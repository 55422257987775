import React from 'react'
import { Field, Form, Formik, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import i18next from "i18next";
import "./contact.scss";

async function handleFormSubmission(values, actions) {

  const headers = new Headers({
    Accept: 'application/json',
    'Content-Type': 'application/json',
  });

  const query = `mutation submit($values: String!) {
    submitForm(values: $values) {
      errors
      submission {
        id
      }
    }
  }`;

  values = { 'webform_id': 'contact', ...values };

  const options = {
    method: 'POST',
    headers,
    body: JSON.stringify({
      query,
      variables: {
        values: JSON.stringify(values)
      }
    })
  };

  try {
    const response = await fetch(`${process.env.DRUPAL_BASE_URL}/graphql`, options);
    if (response.ok) {
      // Success.
      const data = await response.json();
      if (typeof data.data.submitForm.errors !== 'undefined' && data.data.submitForm.errors.length > 0) {
        // Error returned from Drupal while trying to process the request.
        let errorList = document.createElement('ul');
        data.data.submitForm.errors.forEach(function (error) {
          let li = document.createElement('li');
          li.appendChild(document.createTextNode(error));
          errorList.appendChild(li);
        });
        actions.setStatus({
          error: true,
          message: i18next.t('There are validation errors.'),
        });
        document.querySelector('.error').appendChild(errorList);
      } else {
        actions.setStatus({
          success: true,
          message: i18next.t('contact-form-thank-you'),
        });
      }
    } else {
      // Error connecting to Drupal, e.g. the server is unreachable.
      actions.setStatus({
        error: true,
        message: `${response.status}: ${response.statusText}`,
      });
    }
  } catch (e) {
    actions.setStatus({
      error: true,
      message: e.message,
    });
  }

  actions.setSubmitting(false);
}

const required = i18next.t('Required');

const ContactForm = () => (
  <div className="form form--contact">
    <Formik
      initialValues={{ email: '', name: '', subject: '', message: '' }}
      validationSchema={Yup.object({
        name: Yup.string().required(required),
        email: Yup.string()
          .email(i18next.t('Invalid email address'))
          .required(required),
        subject: Yup.string().required(required),
        message: Yup.string().required(required),
      })}
      onSubmit={(values, actions) => {
        handleFormSubmission(values, actions);
      }}
    >
      {({ isSubmitting, isValidating, status }) => (
        <>
          {status && (status.success || status.error) ? (
            <div className={status.success ? 'success' : 'error'}>
              {status.message}
            </div>
          ) : (
            <Form>

              <div className="field field--name">
                <label htmlFor="name">{i18next.t('Name')}:</label>
                <Field type="text" name="name" />
                <ErrorMessage name="name" component="span" />
              </div>

              <div className="field field--email">
                <label htmlFor="email">{i18next.t('Email')}:</label>
                <Field
                  type="email"
                  name="email"
                  placeholder="email@example.com"
                />
                <ErrorMessage name="email" component="span" />
              </div>

              <div className="field field--subject">
                <label htmlFor="subject">{i18next.t('Subject')}:</label>
                <Field type="text" name="subject" />
                <ErrorMessage name="subject" component="span" />
              </div>

              <div className="field field--message">
                <label htmlFor="message">{i18next.t('Message')}:</label>
                <Field name="message" as="textarea" rows={8} />
                <ErrorMessage name="message" component="span" />
              </div>

              {isSubmitting && !isValidating ? (
                <div className="submitting">{i18next.t('form-submitting')}</div>
              ) : (
                <button type="submit" className="field field--submit">
                  {i18next.t('Submit')}
                </button>
              )}
            </Form>
          )}
        </>
      )}
    </Formik>
  </div>
);

export default ContactForm;
