import React from "react";
import Body from "./body";
import ContactForm from "../forms/contact";
import "./page-full.scss"

const PageFull = (props) => {

  const getContentClass = props.entity.entityId === '20' ? 'has-component' : 'no-component';

  return (
    <div className={`page-full ${getContentClass}`}>
      <div className="page-content-wrapper">
        <h1>{props.entity.entityLabel}</h1>
        <div className="page-content">
          <Body className="page-body">{props.entity.body.value}</Body>
          {props.entity.entityId === '20' &&
          <div className="component">
            <ContactForm/>
          </div>
          }
        </div>
      </div>
    </div>
  )
};

export default PageFull;
