import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import PageFull from "../components/node/page-full";

export default ({ data }) => {

  return (
    <Layout>
      <PageFull entity={data.drupal.nodeById.entityTranslation} />
    </Layout>
  )
}

export const query = graphql`
  query($id: String!, $languageId: Drupal_LanguageId!) {
    drupal {
      nodeById(id: $id) {
        ... on Drupal_NodePage {
          entityTranslation(language: $languageId) {
            ...PageFull
          }
        }
      }
    }
  }
`;
